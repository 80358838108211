import {
  CONTACTS,
  HOME,
  BANKRUPTCY,
  EXTRAJUDICIAL,
  PROTECTION,
  REVIEWS,
  ABOUTUS,
} from "../../shared/router/routes";

import "./index.scss";
import Mobheader from "./mobheader";

const Hader = () => {
  return (
    <>
      <header className="header">
        <div className="header__wrapper js_dnd_wraper">
          <div className="wrapper">
            <div className="b-header">
              <div className="b-sevice">
                <a href={HOME}>
                  <img
                    className="b-sevice__img"
                    width={50}
                    height={50}
                    src="./img/logoo1.jpg"
                    alt=""
                  />
                </a>

                <ul className="sevice__level-1">
                  <li className="sevice__item-1">
                    <span>Услуги</span>
                    <ul className="sevice__level-2">
                      <li className="sevice__item-2">
                        <span>
                          <a href={BANKRUPTCY}>Банкротство физических лиц</a>
                        </span>
                      </li>
                      <li className="sevice__item-2">
                        <span>
                          <a href={EXTRAJUDICIAL}>
                            Банкротство юридических лиц
                          </a>
                        </span>
                      </li>
                      <li className="sevice__item-2 border__none">
                        <span>
                          <a href={PROTECTION}>Анализ признаков банкротства</a>
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li className="sevice__item-1 sevice__item-id-234">
                    <span>
                      <a href={ABOUTUS}>О нас</a>
                    </span>
                  </li>
                  <li className="sevice__item-1 sevice__item-id-234">
                    <span>
                      <a href={REVIEWS}>Отзывы и практика</a>
                    </span>
                  </li>
                  <li className="sevice__item-1 sevice__item-id-233">
                    <span>
                      <a href={CONTACTS}>
                        <ins></ins>
                        Контакты
                      </a>
                    </span>
                  </li>
                  <li className="sevice__wa">
                    <a href="https://wa.me/79374651565?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82...">
                      <img
                        width={30}
                        height={30}
                        src="./img/whatsapp.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li className="sevice__item-1 sevice__item-id-233">
                    <a href="tel:+79374651565">+7(937)-465-15-65</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mob">
        <div className="mob__block">
          <Mobheader />
        </div>
      </div>
    </>
  );
};

export default Hader;
