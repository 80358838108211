import { notification } from "antd";
import { API_BREAKPOINT } from "shared/config";

export const handleSendRequest = async (
  phone,
  question,
  setPhone,
  setQuestion
) => {
  console.log({ phone, question });
  try {
    const ws = new WebSocket(API_BREAKPOINT);

    ws.onopen = () => {
      ws.send(`Номер: ${phone}, вопрос/имя: ${question}`);
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.status === "success") {
        notification.success({
          message: "Успешно!",
          description: response.message,
        });
      } else {
        notification.error({
          message: "Ошибка!",
          description: response.message,
        });
      }
      setPhone("");
      setQuestion("");
      ws.close();
    };

    ws.onerror = (error) => {
      console.error("Ошибка WebSocket:", error);
      notification.error({
        message: "Ошибка!",
        description: "Ошибка соединения с сервером",
      });
    };
  } catch (error) {
    console.error(error);
    notification.error({
      message: "Ошибка!",
      description: "Произошла ошибка при отправке запроса",
    });
  }
};
