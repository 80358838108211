import "./intro.scss";

const Intro = () => (
  <>
    <div class="wrapper protection">
      <h1>Методы анализа признаков банкротства</h1>
      <p>
        Для своевременного выявления признаков банкротства необходимо регулярно
        проводить комплексный анализ финансово-хозяйственной деятельности. К
        основным методам анализа относятся:
      </p>
      <ul>
        <li>
          <h3>1. Финансовый анализ</h3>
          <p>
            Финансовый анализ включает оценку бухгалтерской отчетности, расчет
            ключевых финансовых коэффициентов (ликвидность, рентабельность,
            оборачиваемость, финансовая устойчивость) и анализ динамики
            финансовых показателей.
          </p>
        </li>

        <li>
          <h3>2. Оценка кредиторской и дебиторской задолженности</h3>
          <p>
            Анализ структуры и динамики кредиторской и дебиторской задолженности
            позволяет выявить проблемы с ликвидностью и платежеспособностью.
            Важно оценить сроки погашения задолженности и наличие просроченных
            обязательств.
          </p>
        </li>

        <li>
          <h3>3. Мониторинг деловой активности</h3>
          <p>
            Мониторинг объемов производства, продаж, числа клиентов и других
            показателей деловой активности позволяет выявить тенденции и
            отклонения от плановых показателей. Это позволяет своевременно
            реагировать на изменения и предпринимать корректирующие действия.
          </p>
        </li>
        <li>
          <h3>4. Правовой анализ</h3>
          <p>
            Анализ судебных дел, исков и претензий к компании позволяет оценить
            степень юридических рисков и потенциальные финансовые последствия.
            Важно учитывать все судебные споры, связанные с невыполнением
            обязательств и другими финансовыми проблемами.
          </p>
        </li>
      </ul>
    </div>
  </>
);

export default Intro;
