export const reviewConst = [
  {
    id: "A22-817-2022",
    img: "/img/rew1.jpg",
    data: "09.11.2022.",
    name: "Супруги Джембетовы ",
    link: "https://kad.arbitr.ru/Document/Pdf/afea5637-98b0-4f27-8a56-568eed54be99/75cc90ff-1410-4929-8507-d59a3c182be0/A22-817-2022_20221110_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Благодарю команду юристов, за хорошую и быструю работу с моим делом. Рекомендую вас для защиты от долгов.",
  },
  {
    id: "A22-2524-2023",
    img: "/img/rew2.jpg",
    data: "10.01.2024.",
    name: "Оксана Викторовна",
    link: "https://kad.arbitr.ru/Document/Pdf/cbcd0f9c-cdb1-476b-87cc-eccc6aa243a2/230cabba-e017-49a3-ac07-ea72815d33cf/A22-2524-2023_20240117_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Огромная благодарность юристам за быструю и качественную работу с моим делом. Вы настоящие профессионалы! Рекомендую вашу компанию для тех, кто нуждается в финансовой защите.",
  },
  {
    id: "A22-2521-2022",
    img: "/img/rew3.jpg",
    data: "07.02.2023.",
    name: "Ухова Наталья Павловна                       ",
    link: "https://kad.arbitr.ru/Document/Pdf/0af8b377-9761-4eb7-ada2-d850339bc5f0/5383af7b-64cb-4412-bf7b-14bd6721f84b/A22-2521-2022_20230214_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Выражаю искреннюю благодарность вашей команде за оперативную помощь в моем деле о банкротстве. Ваша поддержка и профессионализм – неоценимы. Советую вас всем, кто столкнулся с финансовыми трудностями.",
  },
  {
    id: "A06-2641-2023",
    img: "/img/rew1.jpg",
    data: "06.03.2024.",
    name: "Садыков Аскар Букентаевич",
    link: "https://kad.arbitr.ru/Document/Pdf/33a6452d-6d11-4128-a410-018706a0ea0d/95330f4a-98a2-49a9-9e6f-486a32e7b8ac/A06-2641-2023_20240307_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Благодарю юристов за отличную работу и быстрое решение всех вопросов по моему делу. Ваши знания и опыт помогли мне избавиться от долгов. Рекомендую вас всем, кто нуждается в юридической поддержке.",
  },
  {
    id: "A22-2220-2022",
    img: "/img/rew2.jpg",
    data: "20.03.2023.",
    name: "Огультинова Ольга Борисовна",
    link: "https://kad.arbitr.ru/Document/Pdf/29514f01-2258-4f68-b552-c3245ecc51fa/0e66f636-d6fc-45aa-b71c-d1ad0f3ba1ec/A22-2220-2022_20230320_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Спасибо команде юристов за профессионализм и внимательное отношение к моему делу о банкротстве. Вы сделали все возможное, чтобы помочь мне. Рекомендую вас для решения финансовых проблем.",
  },
  {
    id: "A22-2879-2022",
    img: "/img/rew3.jpg",
    data: "17.03.2023.",
    name: "Евгения Александровна ",
    link: "https://kad.arbitr.ru/Document/Pdf/0d2765e3-470e-4c40-9d18-14366b72af5f/04483e9b-886d-4818-8c2c-90ee4af62937/A22-2879-2022_20230317_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Огромное спасибо юристам за высококачественную и оперативную работу с моим делом. Ваша помощь была неоценимой. Рекомендую вас всем, кто нуждается в защите от долгов.",
  },
];
