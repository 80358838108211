import "./intro.scss";

const Intro = () => (
  <>
    <div className="wrapper bankruptcy">
      <h1>Банкротство физических лиц</h1>
      <p>
        Признаки банкротства физического лица: задолженность составляет не менее
        500 тыс. руб., должник не в состоянии оплачивать денежные обязательства
        и обязательные платежи в установленные сроки, хотя бы перед одним из
        кредиторов; дохода должника и имущества должника недостаточно для
        погашения задолженности.
      </p>
      <p>
        Списанию подлежат все денежные обязательства должника (кредитные
        обязательства, займы), включая коммунальные платежи, задолженность по
        распискам и др., а также задолженность по обязательным платежам
        (налогам, штрафам, пеням).
      </p>
    </div>
  </>
);

export default Intro;
