import "./index.scss";

import Intro from "./intro";
import Application from "../../components/application";
import Review from "../../components/review";

const Bankruptcy = () => (
  <>
    <div>
      <Intro />
      <Review />
      <Application />
    </div>
  </>
);

export default Bankruptcy;
