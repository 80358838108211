import Intro from "./intro";
import Application from "../../components/application";
import Review from "../../components/review";

const Extrajudicial = () => (
  <>
    <div>
      <Intro />
      <Review />
      <Application />
    </div>
  </>
);

export default Extrajudicial;
