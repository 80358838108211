import "./index.scss";

import Intro from "./intro";
import Application from "../../components/application";

const Protection = () => (
  <>
    <div className="bankruptcy">
      <Intro />
      <Application />
    </div>
  </>
);

export default Protection;
