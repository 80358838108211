import "./intro.scss";

const Intro = () => (
  <>
    <div className="l-main l-main--contentbox">
      <div className="wrapper extr">
        <h1>Банкротство юридических лиц</h1>
        <p>
          Юридическое лицо считается неспособным удовлетворить требования
          кредиторов по денежным обязательствам, о выплате выходных пособий и
          (или) об оплате труда лиц, работающих или работавших по трудовому
          договору, и (или) исполнить обязанность по уплате обязательных
          платежей, если соответствующие обязательства и (или) обязанность не
          исполнены им в течение трех месяцев с даты, когда они должны были быть
          исполнены.
        </p>
      </div>
    </div>
  </>
);

export default Intro;
