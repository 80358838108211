import Profils from "./profil";
import MapHome from "../../components/maphome";
import Services from "./services";
import Review from "../../components/review";
import Intro from "./intro";
import Application from "../../components/application";
import { Link } from "react-router-dom";
import { REVIEWS } from "../../shared/router/routes";

const Home = () => (
  <>
    <div className="home">
      <Intro />
      <Services />
      <Review />
      <Link to={REVIEWS}>
        <div className="review__all">ВСЕ ОТЗЫВЫ</div>
      </Link>
      <Application />
      <Profils />
      <MapHome />
    </div>
  </>
);

export default Home;
