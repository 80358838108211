import "./contacts.scss";
import MapHome from "../../components/maphome";
import Application from "components/application";
const Contacts = () => (
  <>
    <div className="contacts">
      <h1>Контакты в Элисте</h1>
      <ul>
        <li>Адрес: г. Элиста, ул. Номто Очирова 8 офис 5</li>
        <li>Телефон: +7 937 465 1565</li>
        <li>E-mail: annakotkaeva@mail.ru Адрес в Элисте</li>
      </ul>
    </div>
    <MapHome />
    <Application />
  </>
);
export default Contacts;
