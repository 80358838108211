const AboutusCard = ({ rev }) => {
  return (
    <div className="aboutus__item">
      <div className="aboutus__inner">
        <a
          className="aboutus__link"
          href={rev.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="aboutus__imgbox">
            <img alt="соцсетии" height={300} src={rev.img} />
            <div>
              <img
                alt="соцсети"
                className="aboutus__imgbox--top"
                width={50}
                src={rev.www}
              />
              <h3>{rev.name}</h3>
              <p>{rev.nikname}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default AboutusCard;
