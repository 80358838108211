import "./review.scss";
import { reviewConst } from "./card/constans.js";
import ReviewCard from "./card/ReviewCard";

const Review = () => (
  <>
    <div className="review ">
      <h1>Отзывы о банкротстве физических лиц</h1>
      <div className="wrapper review__items ">
        {reviewConst.map((item) => (
          <ReviewCard key={item.id} rev={item} />
        ))}
      </div>
    </div>
  </>
);
export default Review;
