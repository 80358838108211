import React, { useState } from "react";
import { handleSendRequest } from "shared/utils";
import "./index.scss";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { CONFID } from "../../shared/router/routes";

const Consultation = () => {
  const [phone, setPhone] = useState("");
  const [question, setQuestion] = useState("");

  const handleInputChange = (e) => {
    let value = e.target.value;

    value = value.replace(/[^\d+]/g, "");

    if (value.startsWith("8")) {
      value = "+7" + value.replace(8, "");
    }

    if (value.length > 12) {
      value = value.slice(0, 12);
    }

    setPhone(value);
  };

  const handleTextareaChange = (e) => {
    setQuestion(e.target.value);
  };

  const onSendRequest = () => {
    if (phone.length < 11) {
      notification.error({
        message: "Ошибка!",
        description: "Телефон должен содержать не менее 11 цифр",
      });
      return;
    }

    if (phone && question) {
      handleSendRequest(phone, question, setPhone, setQuestion);
    } else {
      notification.error({
        message: "Ошибка!",
        description: "Заполните поля",
      });
    }
  };

  return (
    <div className="b-form--head">
      <div className="form__header">
        <div className="b-title-h2">Бесплатная консультация</div>
      </div>

      <form>
        <div className="form__cols-wrap">
          <div className="form__item">
            <input
              data-name="Телефон"
              name="phone"
              type="tel"
              placeholder="Телефон"
              aria-required="true"
              aria-invalid="false"
              value={phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="form__item">
            <div className="form__input">
              <textarea
                data-name="Ваш вопрос"
                placeholder="Напишите вопрос"
                value={question}
                onChange={handleTextareaChange}
              ></textarea>
              <div className="form__textarea_icon"></div>
            </div>
          </div>
          <div className="form__item">
            <button type="button" onClick={onSendRequest} className="b-btnbox">
              Получить консультацию
            </button>
          </div>
          Нажимая на кнопку, я соглашаюсь с условиями
          <br />
          <Link className="form__conf" to={CONFID}>
            Политики конфиденциальности
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Consultation;
