import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HOME,
  CONTACTS,
  BANKRUPTCY,
  EXTRAJUDICIAL,
  PROTECTION,
  ABOUTUS,
  REVIEWS,
  CONFID,
} from "./routes";
import Home from "pages/home";
import Contacts from "pages/contacts";
import Bankruptcy from "pages/bankruptcy";
import Extrajudicial from "pages/extrajudicial";
import Protection from "pages/protection";
import Aboutus from "pages/aboutus";
import Reviews from "pages/reviews";
import NotFound from "pages/NotFound";
import { Confid } from "pages/confid";
const RoutComponent = () => (
  <BrowserRouter>
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={CONTACTS} element={<Contacts />} />
      <Route path={BANKRUPTCY} element={<Bankruptcy />} />
      <Route path={EXTRAJUDICIAL} element={<Extrajudicial />} />
      <Route path={PROTECTION} element={<Protection />} />
      <Route path={ABOUTUS} element={<Aboutus />} />
      <Route path={REVIEWS} element={<Reviews />} />
      <Route path={CONFID} element={<Confid />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
export default RoutComponent;
