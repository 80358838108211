import { useState } from "react";
import { handleSendRequest } from "shared/utils";
import { Button, Modal, notification } from "antd";
import {
  CONTACTS,
  EXTRAJUDICIAL,
  BANKRUPTCY,
  PROTECTION,
  ABOUTUS,
  REVIEWS,
  CONFID,
} from "../../shared/router/routes";
import "./bottom.scss";

const Bottom = () => {
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [question, setQuestion] = useState("");

  const handleInputChange = (e) => {
    let value = e.target.value;

    value = value.replace(/[^\d+]/g, "");

    if (value.startsWith("8")) {
      value = "+7" + value.replace(8, "");
    }

    if (value.length > 12) {
      value = value.slice(0, 12);
    }

    setPhone(value);
  };

  const handleTextareaChange = (e) => {
    setQuestion(e.target.value);
  };

  const onSendRequest = () => {
    if (phone.length < 11) {
      notification.error({
        message: "Ошибка!",
        description: "Телефон должен содержать не менее 11 цифр",
      });
      return;
    }

    if (phone && question) {
      handleSendRequest(phone, question, setPhone, setQuestion);
    } else {
      notification.error({
        message: "Ошибка!",
        description: "Заполните поля",
      });
    }
  };

  return (
    <div className="bottom wrapper">
      <div className="bottom__contacts">
        <div>
          <ul>
            <li>
              <a href={BANKRUPTCY}>Банкротство физических лиц </a>
            </li>
            <li>
              <a href={EXTRAJUDICIAL}>Банкротство юридических лиц</a>
            </li>
            <li>
              <a href={PROTECTION}>Анализа признаков банкротства</a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <a href={ABOUTUS}>О нас</a>
            </li>
            <li>
              <a href={REVIEWS}>Отзывы и практика</a>
            </li>
            <li>
              <a href={CONTACTS}>Контакты</a>
            </li>
          </ul>
          <ul>
            <li>
              <Button type="primary" onClick={() => setOpen(true)}>
                СПИСАТЬ ДОЛГИ
              </Button>
              <Modal
                title="Узнайте как списать долги"
                centered
                open={open}
                onOk={onSendRequest}
                onCancel={() => setOpen(false)}
              >
                <form>
                  <div className="bottom__form">
                    <div className="bottom__form--item">
                      <input
                        data-name="Телефон"
                        name="phone"
                        type="tel"
                        placeholder="Телефон"
                        aria-required="true"
                        aria-invalid="false"
                        value={phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="bottom__form--item">
                      <div className="bottom__form--input">
                        <input
                          placeholder="Введите имя"
                          value={question}
                          onChange={handleTextareaChange}
                        ></input>
                        <div className="form__textarea_icon"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal>
            </li>
            <li>
              <a href="tel:+79374651565">+7(937)-465-15-65</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom__confid">
        <span>
          Bancrotstvork, ИНН: 08030184857,
          <br /> ОГРН: 32408000000175 2021-2024
          <br />
        </span>
        <span>
          <a href={CONFID}>Политика конфиденциальности</a>
        </span>
        <br />
        <span>Разработка корпоративного сайта</span>
      </div>
    </div>
  );
};

export default Bottom;
