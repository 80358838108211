import "./profil.scss";

const Profils = () => (
  <div className="profil wrapper">
    <div className="profil__title">
      <h1>Юристы о банкротстве физических лиц</h1>
    </div>
    <div className="profil__content">
      <section className="profil__content--imgblock">
        <img
          className="profil__content--img"
          src="./img/application2.jpg"
          alt=""
        />
        <div>
          <p>Анна</p> Руководитель основатель компании "Банкротство РК"
        </div>
      </section>
      <section className="profil__content--text">
        Добрый день, дорогие друзья.
        <p>
          Я, Коткаева Анна Викторовна, юрист по Арбитражным спорам, основное
          направление – банкротство.
        </p>
        <p>
          Свой путь я начала 2014 году – 8 лет я обучалась и набиралась опыта в
          банкротстве в Федеральной налоговой службе. Мало кто знает, но факт:
          именно налоговый орган является уполномоченным органом Российской
          Федерации в делах о банкротстве. Таким образом, я получила
          колоссальный опыт и фундаментальные знания в процедурах банкротства.
        </p>
        <p>
          Я практикующий юрист по банкротству со стажем – 10 лет, сотни судебных
          заседаний от первой до кассационной инстанции.
        </p>
        <p>
          В 2021 г. я завершила службу в государственных органах, и ушла в
          свободное плавание. с 2022 г. я начала частную юридическую практику.
        </p>
        <p>
          В этом году я отмечаю 10 лет своей юридической практики в делах по
          банкротству.
        </p>
        <p>
          Мною был пройден длинный путь, и теперь я могу с уверенностью помочь
          каждому.
        </p>
        <p>
          В своей практике я руководствуюсь прежде всего такими ценностями, как:
          индивидуальный подход, сопереживание, комфортное взаимодействие с
          клиентами, доверие.
        </p>
      </section>
    </div>
  </div>
);
export default Profils;
