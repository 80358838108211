import Consultation from "../../../components/consultation";
import "./intro.scss";

const Intro = () => (
  <>
    <div className="intro">
      <div className="intro__background">
        <div class="wrapper">
          <h1 className="intro__title">БАНКРОТСТВО РК</h1>
          <div className="intro__block">
            <div className="intro__block--text">
              <div>
                <div>
                  <div>
                    <h3>Банкротство физических лиц</h3>
                  </div>
                  <ul>
                    <li>Процедура банкротства под ключ</li>
                    <li>Полное сопровождение процедуры</li>
                    <li>Защита прав в суде</li>
                  </ul>
                </div>

                <div>
                  <div>
                    <h3>Банкротство предпринимателя (ИП)</h3>
                  </div>
                  <ul>
                    <li>Процедура банкротства под ключ</li>
                    <li>Полное сопровождение процедуры</li>
                    <li>Защита прав в суде</li>
                  </ul>
                </div>
                <div>
                  <div>
                    <h3>Банкротство юридических лиц</h3>
                  </div>
                  <ul>
                    <li>Процедура банкротство под ключ</li>
                    <li>Полное сопровождение процедуры</li>
                    <li>Торги в процедуре банкротства</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="consultation">
              <Consultation />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
export default Intro;
