import Application from "../../components/application";
import Review from "./review";
import "./index.scss";

const Reviews = () => (
  <>
    <div className="review">
      <Review />
      <Application />
    </div>
  </>
);

export default Reviews;
