import Consultation from "../consultation";
import "./application.scss";

const Application = () => (
  <div className="application">
    <div className="application__content">
      <div className="wrapper">
        <h1>Оставьте заявку на бесплатную консультацию ведущего юриста</h1>
        <div className="application__content--consultation">
          <Consultation />
        </div>
      </div>
    </div>
  </div>
);

export default Application;
