import "./intro.scss";

const Intro = () => (
  <div className="wrapper">
    <div className="intro__title">
      <h1>О НАС</h1>
    </div>
    <div className="intro__content">
      <section className="intro__content--imgblock">
        <img className="intro__content--img" src="./img/abautus.jpg" alt="" />
        <div>
          <p>Анна</p> Руководитель основатель компании "Банкротство РК"
        </div>
      </section>
      <section className="intro__content--text">
        Добрый день, рады приветствовать Вас!
        <p>
          Я, Коткаева Анна Викторовна, и моя не большая, но дружная команда,
          рады помочь каждому, кто сейчас читает это письмо.
        </p>
        <p>
          Хочу обратиться к каждому, кто сейчас находится в затруднительной
          финансовой ситуации, к каждому, кто запутался и ищет выход. В первую
          очередь, хочу сказать: – это может случиться с каждым, каждый из нас
          совершает ошибки, выход есть!
        </p>
        <p>
          Я практикующий юрист по банкротству со стажем – 10 лет, сотни судебных
          заседаний от первой до кассационной инстанции.
        </p>
        <p>
          К каждой процедуре мы подбираем индивидуальный подход. Мы не работаем
          на количество продаж, а работаем на качество процедуры и взаимосвязи с
          клиентом.
        </p>
        <p>
          Если Вам также важны наши ценности, то мы точно справимся с проблемой
          вместе.
        </p>
      </section>
    </div>
  </div>
);
export default Intro;
