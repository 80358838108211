import React from "react";

const NotFound = () => {
    return (
        <div>
            <h1>Error 404!</h1>
           <p>Упс, такой страницы нет или она была удалена!</p>
        </div>
    );
};

export default NotFound;
