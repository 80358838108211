export const HOME = "/";
export const ABOUT = "/about";
export const NEWS = "/news";
export const ADMIN = "/admin";
export const AUTH = "/auth";
export const REVIEW = "/review";
export const CONTACTS = "/contacts";
export const BANKRUPTCY = "/bankruptcy";
export const EXTRAJUDICIAL = "/extrajudicial";
export const PROTECTION = "/protection";
export const ABOUTUS = "/aboutus";
export const REVIEWS = "/reviews";
export const CONFID = "/confid";
