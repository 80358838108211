import Bottom from "../components/bottom";
import Hader from "../components/header";
import RoutComponent from "../shared/router";

const Pages = () => (
  <>
    <Hader />
    <RoutComponent />
    <Bottom />
  </>
);
export default Pages;
