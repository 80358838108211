const ReviewCard = ({ rev }) => {
  return (
    <div className="review__item">
      <div className="review__inner">
        <div className="review__imgbox">
          <div className="review__imgbox--img">
            <p>{rev.data}</p>
            <h3>{rev.name}</h3>
            <p>{rev.id}</p>
          </div>
        </div>

        <div className="review__discripshn">
          <p>{rev.reviw}</p>
          <a
            className="review__link"
            href={rev.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            ПОСМОТРЕТЬ СУДЕБНОЕ РЕШЕНИЕ
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
