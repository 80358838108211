export const reviewConst = [
  {
    id: "A22-817-2022",
    img: "/img/rew1.jpg",
    data: "09.11.2022.",
    name: "Супруги Джембетовы ",
    link: "https://kad.arbitr.ru/Document/Pdf/afea5637-98b0-4f27-8a56-568eed54be99/75cc90ff-1410-4929-8507-d59a3c182be0/A22-817-2022_20221110_Opredelenie.pdf?isAddStamp=True",
    reviw:
      "Благодарю команду юристов, за хорошую и быструю работу с моим делом. Рекомендую вас для защиты от долгов.",
  },
  {
    id: "A22-2524-2023",
    img: "/img/rew2.jpg",
    data: "10.01.2024.",
    name: "Оксана Викторовна",
    link: "https://kad.arbitr.ru/Document/Pdf/cbcd0f9c-cdb1-476b-87cc-eccc6aa243a2/230cabba-e017-49a3-ac07-ea72815d33cf/A22-2524-2023_20240117_Opredelenie.pdf?isAddStamp=True",

    reviw:
      "Благодарю команду юристов, за хорошую и быструю работу с моим делом. Рекомендую вас для защиты от долгов.",
  },
  {
    id: "A22-2521-2022",
    img: "/img/rew3.jpg",
    data: "07.02.2023.",
    name: "Ухова Наталья Павловна                       ",
    link: "https://kad.arbitr.ru/Document/Pdf/0af8b377-9761-4eb7-ada2-d850339bc5f0/5383af7b-64cb-4412-bf7b-14bd6721f84b/A22-2521-2022_20230214_Opredelenie.pdf?isAddStamp=True",

    reviw:
      "Благодарю команду юристов, за хорошую и быструю работу с моим делом. Рекомендую вас для защиты от долгов.",
  },
];
