import Socia from "./socia";
import Application from "../../components/application";
import Review from "../../components/review";
import Intro from "./intro";
import "./index.scss";

const Aboutus = () => (
  <>
    <div className="abautuse">
      <Intro />
      <Socia />
      <Review />
      <Application />
    </div>
  </>
);

export default Aboutus;
