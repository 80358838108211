import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import "./maphome.scss";
const MapHome = () => (
  <YMaps>
    <div>
      <Map
        className="map"
        defaultState={{ center: [46.305551, 44.268151], zoom: 17 }}
      >
        <Placemark
          geometry={[46.305551, 44.268151]}
          // width={40}
          // height={40}

          // properties={getPointData(items.id)}
          // options={getPointOptions()}
        />
      </Map>
    </div>
  </YMaps>
);
export default MapHome;
