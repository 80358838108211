import { Link } from "react-router-dom";

import {
  BANKRUPTCY,
  EXTRAJUDICIAL,
  PROTECTION,
} from "../../../shared/router/routes";

import "./services.scss";

const Services = () => (
  <>
    <div className="services">
      <h1>Услуги по банкротству</h1>
      <div className="wrapper">
        <div class="services__items">
          <Link to={BANKRUPTCY}>
            <div class="services__item services__item-317 ">
              <div class="services__inner">
                <div class="services__imgbox">
                  <img
                    alt="Банкротство физических лиц"
                    title="Фото банкротство физических лиц"
                    width={100}
                    height={100}
                    src="/img/1.png"
                  />
                </div>

                <div class="services__title">Банкротство физических лиц</div>
              </div>
            </div>
          </Link>
          <Link to={EXTRAJUDICIAL}>
            <div class="services__item services__item-318 ">
              <div class="services__inner">
                <div class="services__imgbox">
                  <img
                    alt="Внесудебное банкротство"
                    title="Фото внесудебное банкротство"
                    width={100}
                    height={100}
                    src="/img/2.png"
                  />
                </div>

                <div class="services__title">Банкротство юридических лиц</div>
              </div>
            </div>
          </Link>
          <Link to={PROTECTION}>
            <div class="services__item services__item-319 ">
              <div class="services__inner">
                <div class="services__imgbox">
                  <img
                    alt="Защита от коллекторов"
                    title="Фото защита от коллекторов"
                    width={100}
                    height={100}
                    src="/img/3.png"
                  />
                </div>
                <div class="services__title">Анализ признаков банкротства</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </>
);
export default Services;
