import { useState } from "react";
import {
  CONTACTS,
  HOME,
  BANKRUPTCY,
  EXTRAJUDICIAL,
  PROTECTION,
  ABOUTUS,
  REVIEWS,
} from "../../../shared/router/routes";
import "./mobheader.scss";
const Mobheader = () => {
  const [visble, setVseble] = useState(false);
  return (
    <div className={visble ? "loc" : ""}>
      <nav>
        <a href={HOME}>
          <img
            className="b-sevice__img"
            width={40}
            height={40}
            src="./img/logoo1.jpg"
            alt=""
          />
        </a>
        <div
          onClick={() => {
            setVseble((e) => !e);
          }}
          className={visble ? "burger--btn activ" : "burger--btn"}
        >
          <span className={visble && "burger--span"} />
        </div>
      </nav>
      {visble ? (
        <main>
          <div className={visble ? "menu active" : "menu inactive"}>
            <ul className="">
              <li className="">
                <span>
                  <a href={BANKRUPTCY}>
                    Банкротство физических лиц
                    <ins></ins>
                  </a>
                </span>
              </li>
              <li className="">
                <span>
                  <a href={EXTRAJUDICIAL}>
                    Банкротство юридических лиц
                    <ins></ins>
                  </a>
                </span>
              </li>
              <li className="">
                <span>
                  <a href={PROTECTION}>
                    Анализ признаков банкротства
                    <ins></ins>
                  </a>
                </span>
              </li>

              <li className="">
                <span>
                  <a href={ABOUTUS}>
                    О нас
                    <ins></ins>
                  </a>
                </span>
              </li>

              <li className="">
                <span>
                  <a href={REVIEWS}>
                    <ins></ins>
                    Отзывы и практика
                  </a>
                </span>
              </li>
              <li className="">
                <span>
                  <a href={CONTACTS}>
                    <ins></ins>
                    Контакты
                  </a>
                </span>
              </li>
              <li className="">
                <a href="tel:+79374651565">+7(937)-465-15-65</a>
              </li>
              <li className="sevice__wa">
                <a href="https://wa.me/79374651565?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82...">
                  <img width={30} height={30} src="./img/whatsapp.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </main>
      ) : (
        ""
      )}
    </div>
  );
};

export default Mobheader;
