import "./confid.scss";
export const Confid = () => (
  <div className="wrapper confid">
    <p>
      Настоящая Политика конфиденциальности персональных данных (далее –
      Политика конфиденциальности) действует в отношении всей информации,
      которую сайт Банкротство РК, расположенный на доменном имени
      bankrotstvork.ru, может получить о Пользователе во время использования
      сайта.
    </p>
    <ul>
      <li>
        ОПРЕДЕЛЕНИЕ ТЕРМИНОВ
        <ul>
          <li>
            В настоящей Политике конфиденциальности используются следующие
            термины:
            <ul>
              <li>
                «Администрация сайта (далее – Администрация сайта)» –
                уполномоченные на управление сайтом сотрудники, действующие от
                имени компании Банкротство РК, которые организуют и (или)
                осуществляют обработку персональных данных, а также определяют
                цели обработки персональных данных, состав персональных данных,
                подлежащих обработке, действия (операции), совершаемые с
                персональными данными.
              </li>
              <li>
                «Персональные данные» - любая информация, относящаяся к прямо
                или косвенно определенному или определяемому физическому лицу
                (субъекту персональных данных).
              </li>
              <li>
                «Обработка персональных данных» - любое действие (операция) или
                совокупность действий (операций), совершаемых с использованием
                средств автоматизации или без использования таких средств с
                персональными данными, включая сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение,
                предоставление, доступ), обезличивание, блокирование, удаление,
                уничтожение персональных данных.
              </li>
              <li>
                «Конфиденциальность персональных данных» - обязательное для
                соблюдения Оператором или иным получившим доступ к персональным
                данным лицом требование не допускать их распространения без
                согласия субъекта персональных данных или наличия иного
                законного основания.
              </li>
              <li>
                «Пользователь сайта (далее - Пользователь)» – лицо, имеющее
                доступ к Сайту, посредством сети Интернет и использующее Сайт.
              </li>
              <li>
                «Cookies» — небольшой фрагмент данных, отправленный веб-сервером
                и хранимый на компьютере пользователя, который веб-клиент или
                веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
                попытке открыть страницу соответствующего сайта.
              </li>
              <li>
                «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети,
                построенной по протоколу IP.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        ОБЩИЕ ПОЛОЖЕНИЯ
        <ul>
          <li>
            Использование Пользователем сайта означает согласие с настоящей
            Политикой конфиденциальности и условиями обработки персональных
            данных Пользователя.
          </li>
          <li>
            В случае несогласия с условиями Политики конфиденциальности
            Пользователь должен прекратить использование сайта.
          </li>
          <li>
            Настоящая Политика конфиденциальности применяется только к сайту
            Банкротство РК. Сайт не контролирует и не несет ответственность за
            сайты третьих лиц, на которые Пользователь может перейти по ссылкам,
            доступным на сайте.
          </li>
          <li>
            Администрация сайта не проверяет достоверность персональных данных,
            предоставляемых Пользователем сайта.
          </li>
        </ul>
      </li>
      <li>
        ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
        <ul>
          <li>
            Настоящая Политика конфиденциальности устанавливает обязательства
            Администрации сайта по неразглашению и обеспечению режима защиты
            конфиденциальности персональных данных, которые Пользователь
            предоставляет по запросу Администрации сайта при регистрации на
            сайте или при оформлении заказа на услугу.
          </li>
          <li>
            Персональные данные, разрешённые к обработке в рамках настоящей
            Политики конфиденциальности, предоставляются Пользователем путём
            заполнения различных форм на Сайте Банкротство РК, они включают в
            себя следующую информацию:
            <ul>
              <li>Фамилию, имя, отчество Пользователя;</li>
              <li>Контактный телефон Пользователя;</li>
              <li>Адрес электронной почты (e-mail);</li>
              <li>Адрес доставки документов;</li>
              <li>Место жительство Пользователя.</li>
            </ul>
          </li>
          <li>
            Компания защищает Данные, которые автоматически передаются в
            процессе просмотра рекламных блоков и при посещении страниц сайта,
            на которых установлены скрипты внешней статистики Яндекс Метрика,
            Google Analytics, статистический скрипт системы «пиксель»,
            Callibri/Calltouch/Roistat, ip-телефония Mango, Битрикс24:
            <ul>
              <li>IP адрес;</li>
              <li>информация из cookies;</li>
              <li>
                информация о браузере (или иной программе, которая осуществляет
                доступ к показу рекламы);
              </li>
              <li>время доступа к страницам сайта Компании;</li>
              <li>адрес страницы, на которой расположен рекламный блок;</li>
              <li>реферер (адрес предыдущей страницы).</li>
            </ul>
          </li>
          <li>
            Отключение cookies может повлечь невозможность доступа к частям
            Сайта, требующим авторизации.
          </li>
          <li>
            Сайт осуществляет сбор статистики об IP-адресах своих посетителей.
            Данная информация используется с целью выявления и решения
            технических проблем, для контроля законности проводимых финансовых
            платежей.
          </li>
          <li>
            Любая иная персональная информация неоговоренная выше (история
            покупок, используемые браузеры и операционные системы и т.д.)
            подлежит надежному хранению и нераспространению, за исключением
            случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики
            конфиденциальности.
          </li>
        </ul>
      </li>
      <li>
        ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
        <ul>
          <li>
            Персональные данные Пользователя Администрация сайта может
            использовать в целях:
            <ul>
              <li>
                Идентификации Пользователя, зарегистрированного на сайте, для
                оформления заказа и (или) заключения Договора оказания услуги
                дистанционным способом с Банкротство РК.
              </li>
              <li>
                Предоставления Пользователю доступа к персонализированным
                ресурсам Сайта.
              </li>
              <li>
                Установления с Пользователем обратной связи, включая направление
                уведомлений, запросов, касающихся использования Сайта, оказания
                услуг, обработка запросов и заявок от Пользователя.
              </li>
              <li>
                Определения места нахождения Пользователя для обеспечения
                безопасности, предотвращения мошенничества.
              </li>
              <li>
                Подтверждения достоверности и полноты персональных данных,
                предоставленных Пользователем.
              </li>
              <li>
                Создания учетной записи для совершения покупок, если
                Пользователь дал согласие на создание учетной записи.
              </li>
              <li>Уведомления Пользователя Сайта о состоянии Заказа.</li>
              <li>
                Обработки и получения платежей, подтверждения налога или
                налоговых льгот, оспаривания платежа, определения права на
                получение кредитной линии Пользователем.
              </li>
              <li>
                Предоставления Пользователю эффективной клиентской и технической
                поддержки при возникновении проблем связанных с использованием
                Сайта.
              </li>
              <li>
                Предоставления Пользователю с его согласия, обновлений
                продукции, специальных предложений, информации о ценах,
                новостной рассылки и иных сведений от имени Сайта или от имени
                партнеров Сайта.
              </li>
              <li>
                Осуществления рекламной деятельности с согласия Пользователя.
              </li>
              <li>
                Предоставления доступа Пользователю на сайты или сервисы
                партнеров с целью получения продуктов, обновлений и услуг.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
        <ul>
          <li>
            Обработка персональных данных Пользователя осуществляется без
            ограничения срока, любым законным способом, в том числе в
            информационных системах персональных данных с использованием средств
            автоматизации или без использования таких средств.
          </li>
          <li>
            Пользователь соглашается с тем, что Администрация сайта вправе
            передавать персональные данные третьим лицам, в частности,
            курьерским службам, организациям почтовой связи, операторам
            электросвязи, исключительно в целях выполнения заказа Пользователя,
            оформленного на Сайте Банкротство РК, включая доставку документов.
          </li>
          <li>
            Персональные данные Пользователя могут быть переданы уполномоченным
            органам государственной власти Российской Федерации только по
            основаниям и в порядке, установленным законодательством Российской
            Федерации.
          </li>
          <li>
            При утрате или разглашении персональных данных Администрация сайта
            информирует Пользователя об утрате или разглашении персональных
            данных.
          </li>
          <li>
            Администрация сайта принимает необходимые организационные и
            технические меры для защиты персональной информации Пользователя от
            неправомерного или случайного доступа, уничтожения, изменения,
            блокирования, копирования, распространения, а также от иных
            неправомерных действий третьих лиц.
          </li>
          <li>
            Администрация сайта совместно с Пользователем принимает все
            необходимые меры по предотвращению убытков или иных отрицательных
            последствий, вызванных утратой или разглашением персональных данных
            Пользователя.
          </li>
        </ul>
      </li>
      <li>
        ОБЯЗАТЕЛЬСТВА СТОРОН
        <ul>
          <li>
            Пользователь обязан:
            <ul>
              <li>
                Предоставить информацию о персональных данных, необходимую для
                пользования Сайтом.
              </li>
              <li>
                Обновить, дополнить предоставленную информацию о персональных
                данных в случае изменения данной информации.
              </li>
            </ul>
          </li>
          <li>
            Администрация сайта обязана:
            <ul>
              <li>
                Использовать полученную информацию исключительно для целей,
                указанных в п. 4 настоящей Политики конфиденциальности.
              </li>
              <li>
                Обеспечить хранение конфиденциальной информации в тайне, не
                разглашать без предварительного письменного разрешения
                Пользователя, а также не осуществлять продажу, обмен,
                опубликование, либо разглашение иными возможными способами
                переданных персональных данных Пользователя, за исключением п.п.
                5.2. и 5.3. настоящей Политики Конфиденциальности.
              </li>
              <li>
                Принимать меры предосторожности для защиты конфиденциальности
                персональных данных Пользователя согласно порядку, обычно
                используемого для защиты такого рода информации в существующем
                деловом обороте.
              </li>
              <li>
                Осуществить блокирование персональных данных, относящихся к
                соответствующему Пользователю, с момента обращения или запроса
                Пользователя или его законного представителя либо
                уполномоченного органа по защите прав субъектов персональных
                данных на период проверки, в случае выявления недостоверных
                персональных данных или неправомерных действий.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        ОТВЕТСТВЕННОСТЬ СТОРОН
        <ul>
          <li>
            Администрация сайта, не исполнившая свои обязательства, несёт
            ответственность за убытки, понесённые Пользователем в связи с
            неправомерным использованием персональных данных, в соответствии с
            законодательством Российской Федерации, за исключением случаев,
            предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
            Конфиденциальности.
          </li>
          <li>
            В случае утраты или разглашения Конфиденциальной информации
            Администрация сайта не несёт ответственность, если данная
            конфиденциальная информация:
            <ul>
              <li>Стала публичным достоянием до её утраты или разглашения.</li>
              <li>
                Была получена от третьей стороны до момента её получения
                Администрацией сайта.
              </li>
              <li>Была разглашена с согласия Пользователя.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        РАЗРЕШЕНИЕ СПОРОВ
        <ul>
          <li>
            До обращения в суд с иском по спорам, возникающим из отношений между
            Пользователем сайта и Администрацией сайта, обязательным является
            предъявление претензии (письменного предложения о добровольном
            урегулировании спора).
          </li>
          <li>
            Получатель претензии в течение 30 календарных дней со дня получения
            претензии, письменно уведомляет заявителя претензии о результатах
            рассмотрения претензии.
          </li>
          <li>
            При не достижении соглашения спор будет передан на рассмотрение в
            судебный орган в соответствии с действующим законодательством
            Российской Федерации.
          </li>
          <li>
            К настоящей Политике конфиденциальности и отношениям между
            Пользователем и Администрацией сайта применяется действующее
            законодательство Российской Федерации.
          </li>
        </ul>
      </li>
      <li>
        ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
        <ul>
          <li>
            Администрация сайта вправе вносить изменения в настоящую Политику
            конфиденциальности без согласия Пользователя.
          </li>
          <li>
            Новая Политика конфиденциальности вступает в силу с момента ее
            размещения на Сайте, если иное не предусмотрено новой редакцией
            Политики конфиденциальности.
          </li>
          <li>
            Все предложения или вопросы по настоящей Политике конфиденциальности
            следует сообщать через WhatsApp по номеру +7 (937) 465-15-65.
          </li>
          <li>
            Действующая Политика конфиденциальности размещена на странице по
            адресу .
          </li>
        </ul>
      </li>
    </ul>
  </div>
);
