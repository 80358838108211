export const reviewConst = [
  {
    www: "/img/icon-instagram.png",
    img: "/img/instbrk.png",
    name: "Банкротство РК",
    nikname: "bankrotstvo_rk",
    link: "https://www.instagram.com/bankrotstvo_rk?igsh=MThuc2p3cHF0NGdlZg==",
  },
  {
    www: "/img/icon-vk.png",
    img: "/img/vkanna.png",
    name: "Анна Коткаева",
    nikname: "vk.com/kotkaeva",
    link: "https://vk.com/kotkaeva",
  },
  {
    www: "/img/icon-instagram.png",
    img: "/img/instanna.png",
    name: "Анна Коткаева",
    nikname: "anna_viktorovnak",
    link: "https://www.instagram.com/anna_viktorovnak?igsh=MXNsZW9ldjltNWxlaQ==",
  },
];
